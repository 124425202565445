var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.enableEditItem
      ? _c("div", { staticClass: "w-full" }, [
          _c("div", { staticClass: "form-group" }, [
            _c(
              "div",
              { staticClass: "mb-1" },
              _vm._l(_vm.productTypeList, function (item) {
                return _c(
                  "vs-radio",
                  {
                    key: item.value,
                    staticClass: "mr-2",
                    attrs: {
                      "vs-name": "type",
                      label: item.text,
                      "vs-value": item.value,
                    },
                    on: { change: _vm.onChangeProductType },
                    model: {
                      value: _vm.model.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "type", $$v)
                      },
                      expression: "model.type",
                    },
                  },
                  [_vm._v(_vm._s(item.text))]
                )
              }),
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm.enableEditItem
      ? _c("div", { staticClass: "grid grid-cols-12 mt-5 items-end" }, [
          _vm.model.type == "COU"
            ? _c(
                "div",
                { staticClass: "col-span-11" },
                [
                  _c("select-suggestion", {
                    ref: "select_entity",
                    class: _vm.entity ? "flex-grow" : "flex-grow required",
                    attrs: {
                      max: 20,
                      column: "name",
                      model: "Course",
                      label: _vm.$t("Course"),
                    },
                    model: {
                      value: _vm.entity,
                      callback: function ($$v) {
                        _vm.entity = $$v
                      },
                      expression: "entity",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("entity"),
                          expression: "errors.has('entity')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("entity")))]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.model.type == "CON"
            ? _c(
                "div",
                { staticClass: "col-span-11" },
                [
                  _c("select-suggestion", {
                    ref: "select_entity",
                    class: _vm.entity ? "flex-grow" : "flex-grow required",
                    attrs: {
                      max: 20,
                      column: "title",
                      model: "ClassContent",
                      label: _vm.$t("content"),
                    },
                    model: {
                      value: _vm.entity,
                      callback: function ($$v) {
                        _vm.entity = $$v
                      },
                      expression: "entity",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("entity"),
                          expression: "errors.has('entity')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("entity")))]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.model.type == "AVA"
            ? _c(
                "div",
                { staticClass: "col-span-11" },
                [
                  _c("select-suggestion", {
                    ref: "select_entity",
                    class: _vm.entity ? "flex-grow" : "flex-grow required",
                    attrs: {
                      max: 20,
                      column: "name,description",
                      model: "ContentQuestionnaire",
                      label: _vm.$t("Questionnaire"),
                    },
                    model: {
                      value: _vm.entity,
                      callback: function ($$v) {
                        _vm.entity = $$v
                      },
                      expression: "entity",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("entity"),
                          expression: "errors.has('entity')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("entity")))]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.model.type == "CER"
            ? _c(
                "div",
                { staticClass: "col-span-11" },
                [
                  _c("select-suggestion", {
                    ref: "select_entity",
                    class: _vm.entity ? "flex-grow" : "flex-grow required",
                    attrs: {
                      max: 20,
                      column: "name,description",
                      model: "Certified",
                      label: _vm.$t("certified.title"),
                    },
                    model: {
                      value: _vm.entity,
                      callback: function ($$v) {
                        _vm.entity = $$v
                      },
                      expression: "entity",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("entity"),
                          expression: "errors.has('entity')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("entity")))]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.entity
            ? _c(
                "div",
                { staticClass: "gap-2 items-center justify-end pl-5" },
                [
                  _c("vs-button", {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: "products.items.create",
                        expression: "'products.items.create'",
                      },
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          content: _vm.$t("adicionar"),
                          delay: { show: 500, hide: 100 },
                        },
                        expression:
                          "{\n            content: $t('adicionar'),\n            delay: { show: 500, hide: 100 }\n          }",
                      },
                    ],
                    attrs: {
                      disabled: !_vm.validateForm,
                      color: "primary",
                      icon: "add",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.addItem($event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "w-full gap-2 justify-center pt-2" },
      [
        _c("vs-divider", { staticClass: "mt-4", attrs: { position: "left" } }, [
          _vm._v(" " + _vm._s(_vm.$t("product.composition"))),
        ]),
        _c(
          "vs-table",
          {
            key: _vm.id,
            attrs: {
              "no-data": " ",
              data: _vm.productItems,
              "no-data-text": _vm.$t("nenhum-registro-encontrado"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var data = ref.data
                  return _vm._l(data, function (tr, i) {
                    return _c(
                      "vs-tr",
                      { key: i },
                      [
                        _c("vs-td", { attrs: { data: data[i].id } }, [
                          _c("label", [_vm._v(_vm._s(data[i].id))]),
                        ]),
                        _c("vs-td", { attrs: { data: data[i].type_str } }, [
                          _c("label", [_vm._v(_vm._s(data[i].type_str))]),
                        ]),
                        _c("vs-td", { attrs: { data: data[i].name } }, [
                          _c("label", [_vm._v(_vm._s(data[i].name))]),
                        ]),
                        _c(
                          "vs-td",
                          [
                            _vm.enableEditItem
                              ? _c("feather-icon", {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: "products.items.delete",
                                      expression: "'products.items.delete'",
                                    },
                                  ],
                                  attrs: {
                                    icon: "Trash2Icon",
                                    svgClasses:
                                      "h-5 w-5 mb-1 mr-3 stroke-current text-danger",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.confirmDeleteItem(data[i])
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  })
                },
              },
            ]),
          },
          [
            _c(
              "template",
              { slot: "thead" },
              [
                _c("vs-th", { attrs: { width: "5%" } }, [
                  _vm._v(_vm._s(_vm.$t("ID"))),
                ]),
                _c("vs-th", { attrs: { width: "25%" } }, [
                  _vm._v(_vm._s(_vm.$t("product.type"))),
                ]),
                _c("vs-th", { attrs: { width: "50%" } }, [
                  _vm._v(_vm._s(_vm.$t("name"))),
                ]),
                _c("vs-th", { attrs: { width: "5%" } }, [
                  _vm._v(_vm._s(_vm.$t("actions"))),
                ]),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }