var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        { staticClass: "mb-4" },
        [
          _c(
            "vs-tabs",
            { attrs: { color: _vm.colorx } },
            [
              _c("vs-tab", { attrs: { label: _vm.$t("product.title") } }, [
                _c("div", { staticClass: "flex flex-col gap-4 mt-5" }, [
                  _c("div", { staticClass: "flex flex-col gap-2" }, [
                    _c("div", { staticClass: "w-full" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          { staticClass: "control-label vs-input--label" },
                          [_vm._v(_vm._s(_vm.$t("product.status")))]
                        ),
                        _c(
                          "div",
                          { staticClass: "mb-1" },
                          _vm._l(_vm.statusList, function (item) {
                            return _c(
                              "vs-radio",
                              {
                                key: item.value,
                                staticClass: "mr-2",
                                attrs: {
                                  "vs-name": "status",
                                  label: item.text,
                                  "vs-value": item.value,
                                },
                                model: {
                                  value: _vm.model.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "status", $$v)
                                  },
                                  expression: "model.status",
                                },
                              },
                              [_vm._v(_vm._s(item.text))]
                            )
                          }),
                          1
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "w-full grid grid-cols-3 gap-2" },
                      [
                        _c(
                          "div",
                          { staticClass: "flex col-span-2" },
                          [
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|max:191",
                                  expression: "'required|max:191'",
                                },
                              ],
                              staticClass: "w-full required",
                              attrs: {
                                id: "product_name",
                                label: _vm.$t("product.name"),
                              },
                              model: {
                                value: _vm.model.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "name", $$v)
                                },
                                expression: "model.name",
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("name"),
                                    expression: "errors.has('name')",
                                  },
                                ],
                                staticClass: "text-danger text-sm",
                              },
                              [_vm._v(_vm._s(_vm.errors.first("name")))]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c(
                              "vx-input-group",
                              [
                                _c("vs-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required|numeric",
                                      expression: "'required|numeric'",
                                    },
                                  ],
                                  staticClass: "required",
                                  attrs: {
                                    lang: "pt-BR",
                                    type: "number",
                                    label: _vm.$t("product.price"),
                                  },
                                  model: {
                                    value: _vm.model.price,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, "price", $$v)
                                    },
                                    expression: "model.price",
                                  },
                                }),
                                _c("template", { slot: "prepend" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "append-text btn-addon flex items-end",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "input-group-text-left includeIcon includeIconOnly vs-component",
                                        },
                                        [_vm._v("R$")]
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "w-full" },
                      [
                        _c("label", { staticClass: "text-sm pl-1" }, [
                          _vm._v(_vm._s(_vm.$t("product.description"))),
                        ]),
                        _c("ComplexEditor", {
                          attrs: { editor_data: _vm.model.description },
                          on: {
                            "update:editor_data": function ($event) {
                              return _vm.$set(_vm.model, "description", $event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "w-full" },
                      [
                        _c("label", { staticClass: "text-sm pl-1" }, [
                          _vm._v(_vm._s(_vm.$t("product.how_works"))),
                        ]),
                        _c("ComplexEditor", {
                          attrs: { editor_data: _vm.model.how_works },
                          on: {
                            "update:editor_data": function ($event) {
                              return _vm.$set(_vm.model, "how_works", $event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "w-full" },
                      [
                        _c("label", { staticClass: "text-sm pl-1" }, [
                          _vm._v(_vm._s(_vm.$t("product.guidelines"))),
                        ]),
                        _c("ComplexEditor", {
                          attrs: { editor_data: _vm.model.guidelines },
                          on: {
                            "update:editor_data": function ($event) {
                              return _vm.$set(_vm.model, "guidelines", $event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "w-full flex gap-2 justify-end" },
                    [
                      !_vm.isEdit()
                        ? _c(
                            "vs-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: "products.create",
                                  expression: "'products.create'",
                                },
                              ],
                              attrs: { disabled: !_vm.validateForm },
                              on: { click: _vm.createOrUpdate },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("action.save")) + " ")]
                          )
                        : _vm._e(),
                      _vm.isEdit()
                        ? _c(
                            "vs-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: "products.edit",
                                  expression: "'products.edit'",
                                },
                              ],
                              attrs: { disabled: !_vm.validateForm },
                              on: { click: _vm.createOrUpdate },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("action.save")) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "vs-button",
                        {
                          attrs: { type: "border" },
                          on: { click: _vm.goBack },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
                      ),
                    ],
                    1
                  ),
                  _vm.isEdit()
                    ? _c(
                        "div",
                        { staticClass: "flex flex-wrap items-center" },
                        [
                          _c(
                            "vue-dropzone",
                            {
                              ref: "fileUploadZone",
                              staticStyle: {
                                width: "100%",
                                "margin-bottom": "10px",
                              },
                              attrs: {
                                id: "upload",
                                "use-font-awesome": true,
                                options: _vm.dropzoneOptions,
                                "use-custom-dropzone-options": true,
                                useCustomSlot: true,
                              },
                              on: {
                                "vdropzone-success": _vm.successSendFile,
                                "vdropzone-sending": _vm.sending,
                                "vdropzone-error": _vm.error,
                                "vdropzone-file-added": _vm.added,
                                "vdropzone-queue-complete": _vm.complete,
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "dropzone-custom-content" },
                                [
                                  _c(
                                    "h3",
                                    { staticClass: "dropzone-custom-title" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "arraste-e-solte-para-enviar-o-arquivo"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "subtitle" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "ou-selecione-um-arquivo-do-seu-computador"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isEdit
                    ? _c(
                        "div",
                        { staticClass: "mt-2 w-full " },
                        [
                          _c("media-list", {
                            attrs: {
                              medias: _vm.files,
                              "show-delete": true,
                              "action-on-click": false,
                            },
                            on: { deleteMedia: _vm.deleteFile },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm.isEdit()
                ? _c(
                    "vs-tab",
                    { attrs: { label: _vm.$t("product.composition_prod") } },
                    [
                      _c("div", { staticClass: "vx-row mb-6 mt-5" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col w-full" },
                          [
                            _c("FormProductItem", {
                              attrs: { id: _vm.id, status: _vm.model.status },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }