<template>
  <div>
    <div class="w-full" v-if="enableEditItem">
      <div class="form-group">
        <div class="mb-1">
          <vs-radio v-model="model.type"
                    vs-name="type"
                    v-for="item in productTypeList"
                    :key="item.value"
                    :label="item.text"
                    :vs-value="item.value"
                    v-on:change="onChangeProductType"
                    class="mr-2">{{item.text}}</vs-radio>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 mt-5 items-end" v-if="enableEditItem">
      <div class="col-span-11" v-if="model.type == 'COU'">
        <select-suggestion
            ref="select_entity"
            :max="20"
            :class="entity ? 'flex-grow' : 'flex-grow required'"
            column="name"
            model="Course"
            :label="$t('Course')"
            v-model="entity"/>
            <span class="text-danger text-sm" v-show="errors.has('entity')">{{ errors.first('entity') }}</span>
      </div>
      <div class="col-span-11" v-if="model.type == 'CON'">
        <select-suggestion
            ref="select_entity"
            :max="20"
            :class="entity ? 'flex-grow' : 'flex-grow required'"
            column="title"
            model="ClassContent"
            :label="$t('content')"
            v-model="entity"/>
            <span class="text-danger text-sm" v-show="errors.has('entity')">{{ errors.first('entity') }}</span>
      </div>
      <div class="col-span-11" v-if="model.type == 'AVA'">
        <select-suggestion
            ref="select_entity"
            :max="20"
            :class="entity ? 'flex-grow' : 'flex-grow required'"
            column="name,description"
            model="ContentQuestionnaire"
            :label="$t('Questionnaire')"
            v-model="entity"/>
            <span class="text-danger text-sm" v-show="errors.has('entity')">{{ errors.first('entity') }}</span>
      </div>
      <div class="col-span-11" v-if="model.type == 'CER'">
        <select-suggestion
            ref="select_entity"
            :max="20"
            :class="entity ? 'flex-grow' : 'flex-grow required'"
            column="name,description"
            model="Certified"
            :label="$t('certified.title')"
            v-model="entity"/>
            <span class="text-danger text-sm" v-show="errors.has('entity')">{{ errors.first('entity') }}</span>
      </div>
      <div class="gap-2 items-center justify-end pl-5" v-if="entity">
        <vs-button
          v-permission="'products.items.create'"
          :disabled="!validateForm"
          color="primary"
          icon="add"
          v-tooltip="{
              content: $t('adicionar'),
              delay: { show: 500, hide: 100 }
            }"
          @click="addItem($event)">
        </vs-button>
      </div>
    </div>
    <div class="w-full gap-2 justify-center pt-2">
      <vs-divider class="mt-4" position="left"> {{ $t('product.composition') }}</vs-divider>
      <vs-table no-data=" " :data="productItems" :key="id"
        :no-data-text="$t('nenhum-registro-encontrado')">
        <template slot="thead">
          <vs-th width="5%">{{ $t('ID') }}</vs-th>
          <vs-th width="25%">{{ $t('product.type') }}</vs-th>
          <vs-th width="50%">{{ $t('name') }}</vs-th>
          <vs-th width="5%">{{ $t('actions') }}</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr
            :key="i"
            v-for="(tr, i) in data">
            <vs-td :data="data[i].id">
              <label>{{data[i].id}}</label>
            </vs-td>
            <vs-td :data="data[i].type_str">
              <label>{{data[i].type_str}}</label>
            </vs-td>
            <vs-td :data="data[i].name">
              <label>{{data[i].name}}</label>
            </vs-td>
            <vs-td>
             <feather-icon
              v-permission="'products.items.delete'"
              v-if="enableEditItem"
              icon="Trash2Icon"
              svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-danger"
              @click="confirmDeleteItem(data[i])"/>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>

import standard from '@/services/standard'

import { isAdmin } from '@/util/Util'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SelectSuggestion from '@/components/SelectSuggestion.vue'

import ProductService from '@/services/api/ProductService'


export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    SelectSuggestion,
  },
  props: {
    id: { //ProductId
      default: null,
      type: Number,
    },
    status: { //Product Status
      default: null,
      type: String
    }

  },

  data: () => ({
    service: null,
    productTypeList: [],
    model: {
      type: null,
      model_id: null,
    },
    entity: null,
    productItems: [],
    productItemId: null
  }),
  computed: {
    validateForm() {
      return !this.isEmpty(this.model.type) && !this.isEmpty(this.entity)
    },
    enableEditItem(){
      return this.status != 'RE'
    }
  },
  beforeMount() {
    this.service = ProductService.build(this.$vs)

  },
  mounted() {
    if (this.id) {
      this.loadData(this.id)
      this.loadProductItemTypes()
    }
  },

  methods: {
    requiredClass(whichData) {
      return !this.isEmpty(whichData) ? 'w-full' : 'w-full required'
    },
    loadData(id) {
      this.$vs.loading()

      this.service.getItems(id).then(
        response => {
          this.productItems = response
        },
        error => {
          this.$vs.loading.close()
        }
      ).finally(
        () => {
          this.$vs.loading.close()
        }
      )
    },
    loadProductItemTypes() {
      this.$vs.loading()

      this.service.getProductItemTypes().then(
        response => {
          this.productTypeList = response
        },
        error => {
          this.$vs.loading.close()
        }
      ).finally(
        () => {
          this.$vs.loading.close()
        }
      )
    },
    addItem() {
      this.$vs.loading()

      const modelData = {
        type: this.model.type,
        model_id: this.entity.id
      }

      this.service.addProductItem(this.id, {form : modelData}).then(
        data => {
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          this.clearForm()
          this.loadData(this.id)
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(
            this.$vs,
            this.$t('nao-foi-possivel-realizar-esta-operacao')
          )
        }
      ).finally(
        () => { this.$vs.loading.close() }
      )
    },
    confirmDeleteItem(data) {
      this.productItemId = data.id
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('confirmacao'),
        acceptText: this.$t('sim'),
        cancelText: this.$t('nao'),
        text: this.$t('tem-certeza-que-deseja-excluir-este-registro'),
        accept: this.deleteProductItem,
      })
    },
    deleteProductItem(){
      this.$vs.loading()
      this.service.deleteProductItem(this.id, this.productItemId).then(
        response => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Successo!',
            text: this.$t('operacao-realizada-com-sucesso'),
            color: 'success',
            iconPack: 'feather',
            position: 'top-center',
            icon: 'icon-check-circle'
          })
          this.loadData(this.id)
        },
        error => {this.$vs.loading.close()}
      )
    },
    onChangeProductType(){
      this.clearForm()
    },
    clearForm(){
      this.entity = null
      this.$refs['select_entity'].reset()
    },
    goBack() {
      this.$router.push('/products')
    },
    isAdmin() {
      return isAdmin()
    },
    isEdit() {
      return !_.isNil(this.id)
    }
  }
}
</script>

<style>

</style>
