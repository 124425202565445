<template>
  <div>
    <vx-card class="mb-4">
      <vs-tabs :color="colorx">
        <vs-tab :label="$t('product.title')">
          <div class="flex flex-col gap-4 mt-5">
            <div class="flex flex-col gap-2">
              <div class="w-full">
                <div class="form-group">
                  <label class="control-label vs-input--label">{{$t('product.status')}}</label>
                  <div class="mb-1">
                    <vs-radio v-model="model.status"
                              vs-name="status"
                              v-for="item in statusList"
                              :key="item.value"
                              :label="item.text"
                              :vs-value="item.value"
                              class="mr-2">{{item.text}}</vs-radio>
                  </div>
                </div>
              </div>
              <div class="w-full grid grid-cols-3 gap-2">
                <div class="flex col-span-2">
                  <vs-input
                    id="product_name"
                    class="w-full required"
                    v-validate="'required|max:191'"
                    :label="$t('product.name')"
                    v-model="model.name"/>
                  <span class="text-danger text-sm" v-show="errors.has('name')">{{errors.first('name')}}</span>
                </div>
                <div class="flex">
                  <vx-input-group>
                    <vs-input
                      lang="pt-BR"
                      type="number"
                      v-model="model.price"
                      :label="$t('product.price')"
                      v-validate="'required|numeric'"
                      class="required"/>
                      <template slot="prepend">
                        <div class="append-text btn-addon flex items-end">
                            <span class="input-group-text-left includeIcon includeIconOnly vs-component">R$</span>
                          </div>
                      </template>
                    </vx-input-group>
                  </div>
              </div>
              <div class="w-full">
                <label class="text-sm pl-1">{{ $t('product.description') }}</label>
                <ComplexEditor
                  v-bind:editor_data.sync="model.description"/>
              </div>
              <div class="w-full">
                <label class="text-sm pl-1">{{ $t('product.how_works') }}</label>
                <ComplexEditor
                  v-bind:editor_data.sync="model.how_works"/>
              </div>
              <div class="w-full">
                <label class="text-sm pl-1">{{ $t('product.guidelines') }}</label>
                <ComplexEditor
                  v-bind:editor_data.sync="model.guidelines"/>
              </div>
            </div>
            <div class="w-full flex gap-2 justify-end">
              <vs-button
                v-if="!isEdit()"
                :disabled="!validateForm"
                v-permission="'products.create'"
                @click="createOrUpdate">
                {{ $t('action.save') }}
              </vs-button>
              <vs-button
                v-if="isEdit()"
                :disabled="!validateForm"
                v-permission="'products.edit'"
                @click="createOrUpdate">
                {{ $t('action.save') }}
              </vs-button>

              <vs-button
                type="border"
                @click="goBack">
                {{ $t('common.back') }}
              </vs-button>
            </div>
           <div class="flex flex-wrap items-center" v-if="isEdit()">
              <vue-dropzone
                id="upload"
                ref="fileUploadZone"
                v-on:vdropzone-success="successSendFile"
                v-on:vdropzone-sending="sending"
                v-on:vdropzone-error="error"
                v-on:vdropzone-file-added="added"
                v-on:vdropzone-queue-complete="complete"
                :use-font-awesome="true"
                :options="dropzoneOptions"
                :use-custom-dropzone-options="true"
                :useCustomSlot="true"
                style="width: 100%; margin-bottom: 10px">
                <div class="dropzone-custom-content">
                  <h3 class="dropzone-custom-title">
                    {{ $t('arraste-e-solte-para-enviar-o-arquivo') }}
                  </h3>
                  <div class="subtitle">
                    {{ $t('ou-selecione-um-arquivo-do-seu-computador') }}
                  </div>
                </div>
              </vue-dropzone>
            </div>
            <div class="mt-2 w-full " v-if="isEdit">
              <media-list :medias="files"
                          :show-delete="true"
                          @deleteMedia="deleteFile"
                          :action-on-click="false" />
            </div>
          </div>
        </vs-tab>
        <vs-tab :label="$t('product.composition_prod')" v-if="isEdit()">
          <div class="vx-row mb-6 mt-5">
            <div class="vx-col w-full">
              <FormProductItem :id="id" :status="model.status"/>
            </div>
          </div>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>

<script>

import standard from '@/services/standard'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import { isAdmin } from '@/util/Util'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SelectSuggestion from '@/components/SelectSuggestion.vue'
import ComplexEditor from '../../questionnaires/elements/ComplexEditor.vue'
import MediaList from '@/components/media/MediaList.vue'

import FormProductItem from './FormProductItem.vue'

import ProductService from '@/services/api/ProductService'


export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    SelectSuggestion,
    ComplexEditor,
    MediaList,
    vueDropzone: vue2Dropzone,
    FormProductItem,
  },
  props: {
    id: {
      default: null,
      type: Number
    }
  },

  data: () => ({
    service: null,
    dropzoneOptions: {},
    files: [],
    statusList: [
      { text: 'Criando', value: 'CR' },
      { text: 'Editando', value: 'ED' },
      { text: 'Liberado', value: 'RE' },
      { text: 'Inativo', value: 'DI' },
    ],
    model: {
      id: null,
      name : '',
      price : '',
      status: '',
      description: '',
      how_works: '',
      guidelines: ''
    }
  }),
  computed: {
    validateForm() {
      return !this.isEmpty(this.model.name) && !this.isEmpty(this.model.price)
    }
  },
  beforeMount() {
    this.service = ProductService.build(this.$vs)

    this.dropzoneOptions = {
      url: `${process.env.VUE_APP_API_BASE_URL}/api/v1/products/${this.id}/files`,
      thumbnailWidth: 150,
      addRemoveLinks: true,
      createImageThumbnails: true,
      timeout: 180000,
      //maxFiles: 1,
      headers: { Authorization: `Bearer ${standard.token()}` },
      acceptedFiles: null
    }
  },
  mounted() {
    if (this.id) {
      this.loadData(this.id)
    }
  },

  methods: {
    requiredClass(whichData) {
      return !this.isEmpty(whichData) ? 'w-full' : 'w-full required'
    },
    loadData(id) {
      this.$vs.loading()

      this.service.read(id).then(
        response => {
          this.model = response
          this.files = response.files
        },
        error => {
          this.$vs.loading.close()
        }
      ).finally(
        () => {
          this.$vs.loading.close()
        }
      )
    },
    createOrUpdate() {
      this.$vs.loading()

      const modelData = {
        id: this.model.id,
        name: this.model.name,
        price: this.model.price,
        status: this.model.status,
        description: this.model.description,
        how_works: this.model.how_works,
        guidelines: this.model.guidelines
      }

      this.service.createOrUpdate(modelData).then(
        data => {
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          if(!this.isEdit()){
            this.$router.push(`/products/${data.id}/edit`)
          }
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(
            this.$vs,
            this.$t('nao-foi-possivel-realizar-esta-operacao')
          )
        }
      ).finally(
        () => { this.$vs.loading.close() }
      )
    },
    successSendFile(file, data) {
      this.files.push(data)
      this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
      //remove file trash
      this.$refs.fileUploadZone.removeAllFiles()
    },
    deleteFile(media) {
      this.$vs.loading()

      this.service.deleteFile(this.id, media.id).then(
        data => {
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          this.files = data
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(
            this.$vs,
            this.$t('nao-foi-possivel-realizar-esta-operacao')
          )
        }
      ).finally(
        () => { this.$vs.loading.close() }
      )
    },
    goBack() {
      this.$router.push('/products')
    },
    isAdmin() {
      return isAdmin()
    },
    isEdit() {
      return !_.isNil(this.id)
    }
  }
}
</script>

<style>
.input-group-text-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #eee;
    border-radius: 5px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

</style>
